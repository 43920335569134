.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  //margin-bottom: 20px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}