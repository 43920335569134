footer.main-footer {
  border-top: 1px solid #CCC;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(214, 214, 214, 1) 100%);
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0;

  @include media-breakpoint-down(sm) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  img {
    width: 36px;
  }

  span {
    font-style: italic;
    line-height: 36px;
    vertical-align: middle;
  }

  .about {
    font-family: sans-serif;
  }

  .claim {
    font-style: normal;
    font-size: 1.5rem;
  }

  nav {
    .list-group-item {
      background: inherit;
      border: 0;

      &:first-of-type {
        @include media-breakpoint-down(sm) {
          padding-left: 0;
        }
      }

      a {
        color: #000000;
      }
    }
  }
}
