@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/iconfont/icomoon.eot?ud3jzn');
  src: url('../../fonts/iconfont/icomoon.eot?ud3jzn#iefix') format('embedded-opentype'),
  url('../../fonts/iconfont/icomoon.ttf?ud3jzn') format('truetype'),
  url('../../fonts/iconfont/icomoon.woff?ud3jzn') format('woff'),
  url('../../fonts/iconfont/icomoon.svg?ud3jzn#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin icomoon-icon {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%icomoon-icon {
  @include icomoon-icon;
}

[class^="icon-"], [class*=" icon-"] {
  @extend %icomoon-icon;
}

.icon-frown:before {
  content: "\e90c";
}

.icon-bars:before {
  content: "\e916";
}

.icon-check:before {
  content: "\e90b";
}

.icon-edit:before {
  content: "\e907";
}

.icon-envelope:before {
  content: "\e900";
}

.icon-info:before {
  content: "\e908";
}

.icon-info-circle:before {
  content: "\e909";
}

.icon-level-right:before {
  content: "\e90a";
}

.icon-phone-square:before {
  content: "\e901";
}

.icon-sort:before {
  content: "\e902";
}

.icon-sort-down:before {
  content: "\e903";
}

.icon-sort-up:before {
  content: "\e920";
}

.icon-star:before {
  content: "\e91d";
}

.icon-star-filled:before {
  content: "\e91e";
}

.icon-trash:before {
  content: "\e906";
}

.icon-user:before {
  content: "\e904";
}

.icon-user-circle:before {
  content: "\e905";
}
