body {
  background: #fafafa;
}

p {
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
}

#sidebar {
  width: 280px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 100vh;
  z-index: 999;
  background: #343a40;
  color: #fff;
  transition: all 0.3s;


  [class^="icon-"], [class*=" icon-"] {
    font-size: 1.5rem;
    line-height: 1.5rem;
    vertical-align: bottom;
  }

  .phone, .mail {
    font-size: 1.1rem;
    line-height: 1.5rem;
    vertical-align: middle;

    a:hover {
      text-decoration: underline;
    }
  }

  a,
  a:hover,
  a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
  }

  .active-user {
    padding: 10px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }

  .mCSB_container {
    min-height: 100%;
    padding-bottom: 180px;
  }

  .powered-by {
    position: absolute;
    bottom: 0;
  }

  .sidebar-header {
    width: 100%;
    padding: 15px 10px;
    font-family: sans-serif;

    img {
      width: 36px;
    }

    span {
      font-style: italic;
      line-height: 36px;
      vertical-align: middle;
    }

    .claim {
      font-style: normal;
      font-size: 1.5rem;
    }
  }

  ul {
    &.components {
      padding: 10px 0 20px 0;
      border-bottom: 1px solid #fff;
    }

    p {
      color: #fff;
      padding: 10px;
    }

    li {
      &.active > a,
      a[aria-expanded="true"] {
        color: #fff;
        background: #343a40;
      }

      a {
        padding: 10px;
        font-size: 1.1em;
        display: block;

        &[data-toggle="collapse"] {
          position: relative;
        }

        &:hover {
          color: #343a40;
          background: #fff;
        }
      }
    }
  }
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #343a40;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #343a40;
}

a.article,
a.article:hover {
  background: #343a40 !important;
  color: #fff !important;
}

@include media-breakpoint-down(md) {
  #sidebar {
    left: -280px;

    &.active {
      left: 0;
    }
  }
}