.ui-autocomplete {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}

div.tagsinput {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  height: auto;
}

.form-check {
  padding: 0;
}

input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  margin: 0;

  & + label {
    position: relative;
    margin-left: 0;

    &::before {
      content: " ";
      display: inline-block;
      border: 2px solid #343a40;
      width: 20px;
      height: 20px;
      background: $white;
      vertical-align: top;
      margin-right: 8px;
      @extend %icomoon-icon;
      font-size: 10px;
      padding: 3px;
      position: relative;
      top: 2px;
      cursor: pointer;
      color: $black;
    }
  }

  &:checked {
    & + label {
      &::before {
        content: "\e90b";
      }
    }
  }

  &:focus {
    & + label {
      &::before {
        box-shadow: 0 0 0 1px rgba(0, 123, 255, 0.25);
      }
    }
  }
}