.is-ctl-ticket.is-act-detail {
  .description-container {
    border: 2px solid #f1f1f1;
    border-radius: 2px;
    padding: 25px;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    & + .description-container {
      border-top: 0;
    }

    .tab-pane {
      padding: 15px;
      border: 1px solid #dee2e6;
      border-top: 0;
    }

    textarea {
      min-height: 250px;
    }
  }

  .modal {
    textarea {
      min-height: 250px;
    }
  }

  .posting,
  .description-wrapper {
    @include media-breakpoint-up(md) {
      display: flex;
    }

    .description {
      padding-top: 10px;
      word-break: break-word;

      @include media-breakpoint-up(md) {
        width: calc(100% - 150px);
        padding-right: 20px;
        padding-top: 25px;
      }
    }

    .author {
      padding: 15px;

      @include media-breakpoint-up(md) {
        width: 150px;
      }

      [class^="icon-"], [class*=" icon-"] {
        font-size: 2rem;
      }

      .user-icon {
        [class^="icon-"], [class*=" icon-"] {
          font-size: 3rem;
        }
      }

      a {
        color: $black;
        text-decoration: none;
      }

      @include media-breakpoint-up(md) {
      & + .description {
        padding-left: 20px;
        padding-right: 0;
      }
      }
    }
  }

  .posting {
    position: relative;

    @include media-breakpoint-up(md) {
      .badges {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  .ticket-properties {
    border: 2px solid #f1f1f1;
    border-top: 0;
    border-radius: 2px;
    padding: 25px;
    background: #fff;
  }

  .card {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
}