.is-ctl-error {
  #content {
    min-height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 80px;

    @include media-breakpoint-down(md) {
      min-height: calc(100vh - 200px);
    }
  }

  .error-page {
    text-align: center;
    margin: 0 auto;

    h1 {
      font-size: 7rem;

      .sub-title {
        display: block;
        font-size: 2rem;
        color: grey;
      }
    }

    p {
      margin: 0;
    }

    .icon {
      margin-bottom: 1rem;

      span {
        font-size: 10rem;
      }
    }
  }
}