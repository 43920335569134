$font-family-sans-serif: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

$primary:       $blue;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

$theme-colors: ();
$theme-colors: map-merge(
                (
                        "primary":    $primary,
                        "secondary":  $secondary,
                        "success":    $success,
                        "info":       $info,
                        "warning":    $warning,
                        "danger":     $danger,
                        "light":      $light,
                        "dark":       $dark,
                        "lavender":   #e6e6fa,
                        "beige":      #f9f3e3
                ),
                $theme-colors
);

@import "~bootstrap/scss/bootstrap";
@import "~malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";
@import "~select2/dist/css/select2.css";
@import "~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker3.css";
@import "~jquery-ui/themes/base/core.css";
@import "~jquery-ui/themes/base/menu.css";
@import "~jquery-ui/themes/base/theme.css";
@import "~jquery-ui/themes/base/autocomplete.css";
@import "~jquery-tags-input/dist/jquery.tagsinput.min.css";

@import "app/error-page";
@import "app/font";
@import "app/form";
@import "app/index";
@import "app/iconfont";
@import "app/sidebar";
@import "app/navbar";
@import "app/table";
@import "app/footer";
@import "app/signin";
@import "app/password-reset";
@import "app/ticket-detail";
@import "app/pages";
@import "app/datepicker";
@import "app/loading-overlay";
