body {
  &.is-ctl-security.is-act-login {
    .content {
      min-height: 100vh;
      display: flex;
      align-items: center;
      padding-top: 40px;
      padding-bottom: 80px;
      background-color: #f5f5f5;
    }
  }

  .form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;

    h1 {
      color: #fff;
    }

    .form-check-label {
      color: #fff;
    }

    .form-control {
      position: relative;
      box-sizing: border-box;
      height: auto;
      padding: 10px;
      font-size: 16px;
    }

    .form-control:focus {
      z-index: 2;
    }

    input[name="username"] {
      margin-bottom: -1px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    input[type="password"] {
      margin-bottom: 10px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

