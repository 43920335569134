table.table {
  margin-bottom: 0;

  thead {
    background: #343a40;
    color: #ffffff;
  }

  th {
    white-space: nowrap;
  }

  tbody tr {
    cursor: pointer;

    &.bg-danger {
      background: rgba($danger, 0.3) !important;
    }
  }

  .simple-tree-table-handler {
    display: none;
  }

  .simple-tree-table-closed,
  .simple-tree-table-opened {
    .simple-tree-table-handler {
      display: inline-block;
    }
  }

  &.table-striped {
    tbody tr {
      &:nth-of-type(odd) {
        .simple-tree-table-icon {
          background-color: #fff;
        }
      }
    }
  }

  &.table-hover {
    tbody tr:hover {
      .simple-tree-table-icon {
        background-color: #fff;
      }
    }
  }

  .non-read {
    font-weight: bold;
  }

}

