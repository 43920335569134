.loading-overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  align-items: center;
  justify-content: center;

  .spinner-border {
    width: 4rem;
    height: 4rem;
    border-width: 0.4rem;
  }

  &.active {
    display: flex;
    opacity: 1;
  }
}