html,
body {
  //height: 100%;
  min-height: 100vh;
  min-width: 360px;
  position: relative;
}

.wrapper {
  display: flex;
  width: 100%;
}

#content-wrapper {
  //width: calc(100% - 250px);
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-bottom: 53px;
  
  @include media-breakpoint-down(sm) {
    padding-bottom: 100px;
  }

  .navbar {
    transition: all 0.3s;
  }
}

#sidebar + #content-wrapper {
  left: 280px;
}

.overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  opacity: 0;
  transition: all 0.5s ease-in-out;

  &.active {
    display: block;
    opacity: 1;
  }
}

#content {
  //padding: 20px;
  position: relative;
}

@include media-breakpoint-down(md) {
  #content-wrapper {
    left: 0;

    &.active {

      .navbar {
        margin-left: 280px;
      }
    }
  }

  #sidebar + #content-wrapper {
    left: 0;
  }
}