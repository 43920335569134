.is-ctl-security {
  &.is-act-reset-password,
  &.is-act-create-password,
  &.is-act-reset-password-request-success,
  &.is-act-reset-password-request {
    .content {
      min-height: 100vh;
      display: flex;
      align-items: center;
      padding-top: 40px;
      padding-bottom: 80px;
      background-color: #f5f5f5;
    }
  }
}